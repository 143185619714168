/* src/pages/Contacto/Contacto.css */
.contacto-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contacto-header {
    text-align: center;
    padding: 40px 0;
    background: rgb(255, 254, 254);
    border-radius: 8px;
    margin-bottom: 50px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .contacto-header h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .contacto-header p {
    font-size: 1.1rem;
    color: black;
  }
  
  .contacto-formulario {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin-bottom: 60px;
  }
  
  .contacto-formulario h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-size: 1rem;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea {
    width: 98%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .btn-submit {
    background: black;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin: 20px auto;
    display: block;
  }
  
  .btn-submit:hover {
    background: #0056b3;
  }

  .success-message {
    color: green;
    margin-top: 10px;
    text-align: center;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
  }