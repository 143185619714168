/* src/components/MenuHamburguesa/MenuHamburguesa.css */
.menu-hamburguesa {
    position: absolute; /* Cambia a absolute para ajustar la posición más fácilmente */
    left: 250px; /* Ajusta la distancia desde el borde izquierdo */
    top: 30px; /* Ajusta la distancia desde el borde superior */
    font-size: 35px; /* Aumenta el tamaño del icono */
    z-index: 1100; /* Asegura que esté por encima de otros elementos */
  }
  
  .menu-button {
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: white;
  }
  
  .menu-content {
    position: absolute;
    top: 100%;
    right: 0;
    background: #000;
    color: white;
    padding: 10px;
    display: none;
    flex-direction: column;
    border-radius: 5px;
  }
  
  .menu-content.open {
    display: flex;
  }
  
  .menu-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-content li {
    margin: 15px 0;
    border-top: 1px solid #444; /* Añade una línea de separación arriba de cada elemento */
    border-bottom: 1px solid #444; /* Añade una línea de separación */
    padding-bottom: 7px; /* Espacio adicional debajo de cada elemento */
  }
  
  .menu-content a {
    color: white;
    text-decoration: none;
    font-size: 26px;
  }
  
  .menu-content a:hover {
    text-decoration: underline;
  }
  
  