body {
  overflow-x: hidden;
}

.texto-parallax {
    position: relative;
    text-align: center;
    margin-bottom: 60px; /* Espacio entre componentes */
    overflow: hidden; /* Evita el desbordamiento horizontal */
  }
  
  .text-content {
    margin-bottom: 20px;
  }
  
  .parallax-image img {
    width: 100%;
    max-width: 600px;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .parallax-image img {
      width: 100%;
      max-width: none;
    }
  }
  
  
  
  
  
  