/* Contenedor del carrusel */
.carrusel {
  position: relative;
  width: 100%;
  height: 25vh; /* Reducimos un poco la altura para móviles */
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* Imagen del carrusel */
.carrusel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 5s ease-in-out;
  transform-origin: bottom center; /* Establece el origen de la transformación en la parte inferior central */
}

/* Efecto de zoom in en la imagen actual */
.slick-current .carrusel-image {
  transform: scale(1.1); /* Escala la imagen para hacer zoom */
}

.slick-slide {
  overflow: hidden;
  height: 100%;
}

.slick-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Logo en el carrusel */
.carrusel-logo {
  position: absolute;
  top:80%; /* Ajustamos para bajar un poco el logo */
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: auto;
  z-index: 10;
}

/* Ajustes para pantallas más grandes */
@media (min-width: 769px) {
  .carrusel {
    height: 75vh;
  }

  .carrusel-logo {
    width: 150px;
    top: auto;
    bottom: 20px;
  }
}

/* Aseguramos que el contenido siguiente esté pegado al carrusel */
.content-below-carousel {
  margin-top: 0;
  padding-top: 0;
}

/* Forzamos la eliminación de espacios */
body, #root, .app-container {
  margin: 0;
  padding: 0;
}








