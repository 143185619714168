.parallax-container {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  
  .plx-alhambra {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .alhambra-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .arboles-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    pointer-events: none;
  }
  
  .arboles-image {
    width: 100%;
    height: auto;
    display: block;
    transform: translateY(20%); /* Ajusta este valor según necesites */
  }
  
  .centered-text {
    position: absolute;
    top: 15%;
    left: 45%;
    transform: translate(-50%, -50%);
    z-index: 3;
    text-align: center;
    color: white;
    font-size: 2rem;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  
  .centered-text h2 {
    margin: 0;
  }
  
  .centered-text h2 {
    font-family: 'Tangerine', cursive;
    font-size: 4rem; /* Aumentamos el tamaño para que sea más visible */
    font-weight: 700;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.7); /* Sombra más pronunciada para mayor legibilidad */
  }

  .content h1 {
    font-family: 'Tangerine';
    font-weight: 1000;
    font-size: 6rem; 
    text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
}

.parallax-wrapper p {
    font-family: 'Tangerine';
    font-weight: 700;
    font-size: 3rem; 
    text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
}
  
  /* Ajustes para móviles */
@media (max-width: 768px) {
    .plx-alhambra-mobile {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    .alhambra-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; /* Centra la imagen */
      transform: scale(1.2); /* Ajusta este valor para zoom */
    }

    .parallax-wrapper h1 {
        font-family: 'Tangerine';
        font-weight: 1000;
        font-size: 4rem; 
        text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
    }
  
    .parallax-wrapper p {
      font-family: 'Tangerine';
      font-weight: 700;
      font-size: 2.5rem; 
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }

    .parallax-container {
      height: 50vh; /* Reduce la altura del contenedor en móviles */
    }

    @media (max-width: 768px) {
        .centered-text {
            top: 15%; /* Ajusta este valor para mover el texto más arriba o abajo */
            transform: translate(-50%, 0);
        }
    }
  }