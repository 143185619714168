.aviso-legal-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: 'Raleway', sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #000;
  }
  
  .aviso-legal-container h2 {
    font-size: 2em;
    margin-top: 30px;
    border-bottom: 2px solid #000;
    padding-bottom: 10px;
    color: #000;
  }
  
  h3 {
    font-size: 1.5em;
    margin-top: 20px;
    color: #000;
  }
  
  p {
    font-size: 1em;
    margin-bottom: 15px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  