.carta {
    position: relative;
    width: 300px;
    height: 300px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .carta-header {
    background: lightblue;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px 15px 0 0; /* Ajusta el borde redondeado para la parte superior */
  }
  
  .carta-icon {
    font-size: 5rem; /* Tamaño del icono */
    color: white; /* Color del icono */
  }
  
  .carta-body {
    padding: 20px;
  }
  
  .carta-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .carta-description {
    font-size: 1rem;
  }
  
  .carta-footer {
    padding: 20px;
    text-align: center;
  }
  
  .carta-button {
    background: lightblue;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  
  
  