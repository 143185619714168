/* src/components/Cartas/Cartas.css */
.cartas-section {
    padding: 40px 20px;
    background-color: #f8f9fa; /* Color de fondo de la sección */
    text-align: center;
  }
  
.cartas-container {
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center; /* Centra las tarjetas horizontalmente */
    gap: 100px; /* Ajusta el espaciado entre tarjetas (reducción del espacio) */
    flex-wrap: wrap; /* Permite que las tarjetas se envuelvan si hay falta de espacio */
}

  /* src/components/Carta/Carta.css */
.carta {
  cursor: pointer;
  border: 2px solid #ddd;
  overflow: hidden;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
}

.carta:hover {
  border-color: #007bff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.carta-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  transition: transform 0.3s ease; /* Añade la transición aquí */
}

.carta-icon {
  font-size: 74px;
  color: #333;
  transition: transform 0.3s ease; /* Transición para el icono */
}

.carta:hover .carta-icon {
  transform: scale(1.3); /* Aumenta el tamaño del icono */
}

.carta-body {
  text-align: center;
}

.carta-title {
  font-size: 18px;
  margin-bottom: 8px;
  color: black;
}

.carta-description {
  font-size: 14px;
  color: #666;
}

.carta-button {
  background-color: black;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carta-button:hover {
  background-color: #0056b3;
}
